/* eslint-disable import/prefer-default-export */
import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import rootReducer from 'src/store/reducers';
import { ENABLE_REDUX_LOGGER } from 'src/config';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootSaga from 'src/store/sagas';

const loggerMiddleware = createLogger();
const sagaMiddleware = createSagaMiddleware();

export function configureStore(preloadedState = {}) {
  const middlewares = [sagaMiddleware, thunkMiddleware];

  if (ENABLE_REDUX_LOGGER) {
    middlewares.push(loggerMiddleware);
  }

  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares)
  );

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);

  const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['account']
  };

  const store = createStore(
    persistReducer(persistConfig, rootReducer),
    preloadedState,
    composedEnhancers
  );

  persistStore(store);

  sagaMiddleware.run(rootSaga);

  return store;
}

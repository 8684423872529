import { combineReducers } from 'redux';
import accountReducer from 'src/store/reducers/accountReducer';
import providerReducer from 'src/store/reducers/providerReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  provider: providerReducer
});

export default rootReducer;

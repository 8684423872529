import React from 'react';

import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: '262px',
    backgroundColor: '#f6f6f6'
  },
  title: {
    padding: theme.spacing(3, 2, 1, 2),
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
    color: theme.palette.common.black
  },
  content: {
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(2)
  },
  contentText: {
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    color: theme.palette.common.black,
    marginBottom: 0
  },
  actions: {
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  button: {
    margin: '0 !important',
    width: '100%'
  },
  primaryButton: {
    backgroundColor: '#0072ec',
    color: theme.palette.common.white
  },
  secondaryButton: {
    color: theme.palette.common.black,
    borderColor: '#e6e7eb',
    backgroundColor: theme.palette.common.white
  }
}));

const PasswordDialog = ({
  isOpen, onClose, onConfirm, title, message, confirmLabel, cancelLabel
}) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      fullWidth
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        disableTypography
        className={classes.title}
        id="alert-dialog-title"
      >
        {title}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText
          className={classes.contentText}
          id="alert-dialog-description"
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          className={clsx(classes.button, classes.primaryButton)}
          variant="contained"
          color="primary"
          size="large"
          onClick={onConfirm}
        >
          {confirmLabel}
        </Button>
        { cancelLabel && (
          <Button
            className={clsx(classes.button, classes.secondaryButton)}
            variant="outlined"
            color="default"
            size="large"
            onClick={onClose}
          >
            {cancelLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

PasswordDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string
};

export default PasswordDialog;

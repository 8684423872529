import authService from 'src/services/authService';
import accountService from 'src/services/accountService';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const UPDATE_PROFILE = '@account/update-profile';
export const FETCH_ROLES = '@account/fetch-roles';
export const FETCH_PERMISSIONS = '@account/fetch-permissions';
export const RECEIVE_PERMISSIONS = '@account/receive-permissions';
export const RECEIVE_ROLES = '@account/receive-roles';
export const FETCH_ROLES_FAILURE = '@account/fetch-roles-failure';
export const MFA_REQUIRED = '@account/mfa-required';
export const REGISTER_MFA_REQUEST = '@account/register-mfa-request';
export const VERIFY_MFA_REQUEST = '@account/verify-mfa-request';
export const MFA_FAILURE = '@account/mfa-failure';

export function login(email, password) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const authResponse = await authService.loginWithEmailAndPassword(email, password);
      if (authResponse.mfa) {
        dispatch({
          type: MFA_REQUIRED,
          payload: {
            mfa: authResponse.mfa
          }
        });
      } else {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            user: authResponse
          }
        });
        dispatch({ type: FETCH_ROLES, payload: { user: authResponse } });
      }
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user) {
  return async (dispatch) => {
    dispatch({ type: FETCH_ROLES, payload: { user } });
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user
      }
    });
  };
}

export function setActivePermissions(provider) {
  return (dispatch) => {
    dispatch({
      type: FETCH_PERMISSIONS,
      payload: {
        provider
      }
    });
  };
}

export function logout() {
  return async (dispatch) => {
    authService.logout();

    dispatch({
      type: LOGOUT
    });
  };
}

export function updateProfile(data) {
  return async (dispatch) => {
    const response = await accountService.updateAccount(data);
    dispatch({
      type: UPDATE_PROFILE,
      payload: {
        user: response.data
      }
    });
  };
}

export function registerMFAToken(token, trustDevice, sessionKey) {
  return async (dispatch) => {
    try {
      dispatch({ type: REGISTER_MFA_REQUEST });

      const user = await authService.registerMFAToken(token, trustDevice, sessionKey);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
      dispatch({
        type: FETCH_ROLES,
        payload: { user }
      });
    } catch (error) {
      dispatch({
        type: MFA_FAILURE,
        payload: { error }
      });
      throw error;
    }
  };
}

export function verifyMFAToken(token, trustDevice, sessionKey) {
  return async (dispatch) => {
    try {
      dispatch({ type: VERIFY_MFA_REQUEST });

      const user = await authService.verifyMFAToken(token, trustDevice, sessionKey);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
      dispatch({ type: FETCH_ROLES, payload: { user } });
    } catch (error) {
      dispatch({ type: MFA_FAILURE, payload: { error } });
      throw error;
    }
  };
}

/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  PROVIDERS_REQUEST,
  PROVIDERS_SUCCESS,
  PROVIDERS_FAILURE,
  SWITCH_PROVIDER
} from 'src/store/actions/providerActions';
import axios from 'src/utils/axios';

const initialState = {
  providers: null,
  activeProvider: null
};

const providerReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROVIDERS_REQUEST: {
      return produce(state, (draft) => {
        draft.providers = null;
      });
    }

    case PROVIDERS_SUCCESS: {
      const { providers } = action.payload;

      return produce(state, (draft) => {
        draft.providers = providers;
        const switchProvider = state.activeProvider == null
          || providers.filter((provider) => provider.id === state.activeProvider.id).length === 0;
        if (switchProvider) {
          [draft.activeProvider] = providers;
          // axios.defaults.headers.common['X-PROVIDER-ID'] = draft.activeProvider.id;
          localStorage.setItem('activeProviderId', draft.activeProvider.id);
        } else {
          // axios.defaults.headers.common['X-PROVIDER-ID'] = state.activeProvider.id;
          localStorage.setItem('activeProviderId', draft.activeProvider.id);
        }
      });
    }

    case PROVIDERS_FAILURE: {
      return produce(state, (draft) => {
        draft.providers = null;
        draft.activeProvider = null;
        // FIXME: this can potentially cause a bug.
        localStorage.removeItem('activeProviderId');
        delete axios.defaults.headers.common['X-PROVIDER-ID'];
      });
    }

    case SWITCH_PROVIDER: {
      const { provider } = action.payload;

      return produce(state, (draft) => {
        draft.activeProvider = provider;
        // axios.defaults.headers.common['X-PROVIDER-ID'] = provider.id;
        localStorage.setItem('activeProviderId', provider.id);
      });
    }

    default: {
      return state;
    }
  }
};

export default providerReducer;

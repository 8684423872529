/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SILENT_LOGIN,
  UPDATE_PROFILE,
  RECEIVE_ROLES,
  FETCH_PERMISSIONS,
  RECEIVE_PERMISSIONS,
  MFA_REQUIRED,
  REGISTER_MFA_REQUEST,
  VERIFY_MFA_REQUEST,
  MFA_FAILURE
} from 'src/store/actions/accountActions';

const initialState = {
  user: null,
  roles: [],
  permissions: [],
  mfa: null
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.user = null;
      });
    }

    case LOGIN_SUCCESS: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
        draft.mfa = null;
      });
    }

    case LOGIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case FETCH_PERMISSIONS: {
      return state;
    }

    case LOGOUT: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case SILENT_LOGIN: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case RECEIVE_ROLES: {
      const { roles } = action.payload;
      return produce(state, (draft) => {
        draft.roles = roles;
      });
    }

    case RECEIVE_PERMISSIONS: {
      const { permissions } = action.payload;
      return produce(state, (draft) => {
        draft.permissions = permissions;
      });
    }

    case UPDATE_PROFILE: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case MFA_REQUIRED: {
      const { mfa } = action.payload;

      return produce(state, (draft) => {
        draft.user = null;
        draft.mfa = mfa;
      });
    }

    case REGISTER_MFA_REQUEST: {
      return state;
    }

    case VERIFY_MFA_REQUEST: {
      return state;
    }

    case MFA_FAILURE: {
      const { mfa } = action.payload.error;
      return produce(state, (draft) => {
        if (mfa && mfa.status) {
          draft.user = null;
          draft.mfa = mfa;
        } else {
          draft.user = null;
          draft.mfa = null;
        }
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;

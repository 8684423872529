import { createSelector } from 'reselect';

const selectAccount = (state) => state.account;

const selectActiveProvider = (state) => state.provider.activeProvider;

const selectRolesByProviderId = createSelector(
  selectAccount,
  selectActiveProvider,
  (account) => account.roles
    .reduce((a, b) => b, {})
);

export {
  selectAccount,
  selectActiveProvider,
  selectRolesByProviderId
};

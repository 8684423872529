import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Button,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  Link
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import { THEMES } from 'src/constants';
import {
  useSelector
} from 'react-redux';
import TopBarLogo from 'src/components/TopBarLogo';
import Account from './Account';
import Providers from './Providers';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.main
      }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
        backgroundColor: theme.palette.background.default
      }
      : {})
  },
  stickyButton: {
    position: 'fixed;',
    bottom: '10px',
    right: '10px',
    borderRadius: '20px',
    padding: '10px 20px',
    textTransform: 'capitalize',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: '#1761B8',
    },
  },
  toolbar: {
    minHeight: 64
  }
}));

const openNewWindow = (url) => {
  window.open(url, '_blank');
};

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const classes = useStyles();
  const account = useSelector((state) => state.account);

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton className={classes.menuButton} color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to={account.user?.type === 'INTERNAL' ? '/app/reports/dashboard' : '/app/reports/home'}>
            <TopBarLogo />
          </RouterLink>
        </Hidden>
        <Box
          ml={2}
          flexGrow={1}
        />
        <Link
          color="inherit"
          href="https://carry1st-help.notion.site/Help-02c791da6da242ff87d01e5ecb84cbe5"
          target="_blank"
        >
          <Box
            display="flex"
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
            marginLeft="1.5em"
          >
            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill="white" d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 17H9V15H11V17ZM13.07 9.25L12.17 10.17C11.67 10.68 11.31 11.14 11.13 11.86C11.05 12.18 11 12.54 11 13H9V12.5C9 12.04 9.08 11.6 9.22 11.19C9.42 10.61 9.75 10.09 10.17 9.67L11.41 8.41C11.87 7.97 12.09 7.31 11.96 6.61C11.83 5.89 11.27 5.28 10.57 5.08C9.46 4.77 8.43 5.4 8.1 6.35C7.98 6.72 7.67 7 7.28 7H6.98C6.4 7 6 6.44 6.16 5.88C6.59 4.41 7.84 3.29 9.39 3.05C10.91 2.81 12.36 3.6 13.26 4.85C14.44 6.48 14.09 8.23 13.07 9.25Z" />
            </svg>
          </Box>
        </Link>
        <Providers />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
      <Button
        onClick={() => openNewWindow('https://airtable.com/shrz7c7nfkNUopevt')}
        className={classes.stickyButton}
      >
        <svg style={{ marginRight: '5px' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill="white" d="M18 4H17V12C17 12.55 16.55 13 16 13H4V14C4 15.1 4.9 16 6 16H16L20 20V6C20 4.9 19.1 4 18 4ZM15 9V2C15 0.9 14.1 0 13 0H2C0.9 0 0 0.9 0 2V15L4 11H13C14.1 11 15 10.1 15 9Z" />
        </svg>
        <p>
          Beta Suggestion Box
        </p>
      </Button>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;

import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { logout } from 'src/store/actions/accountActions';
import {
  Avatar,
  Box,
  ButtonBase,
  Divider,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  SvgIcon
} from '@material-ui/core';
import { ChevronDown as DownArrow } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

function Account() {
  const classes = useStyles();
  const { push } = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await dispatch(logout());
      push('/login');
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" component={ButtonBase} onClick={handleOpen} ref={ref}>
        <Avatar>
          {account.user.firstName.charAt(0)}
        </Avatar>
        <SvgIcon fontSize="small">
          <DownArrow />
        </SvgIcon>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem>
          <Link color="inherit" component={RouterLink} to="/app/account">
            Account Settings
          </Link>
        </MenuItem>
        <Divider />
        <MenuItem>
          <Link color="inherit" component={RouterLink} to="/app/account/change-password">
            Change Password
          </Link>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

export default Account;

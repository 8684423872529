import providerService from 'src/services/providerService';

export const PROVIDERS_REQUEST = '@provider/providers-request';
export const PROVIDERS_SUCCESS = '@provider/providers-success';
export const PROVIDERS_FAILURE = '@provider/providers-failure';
export const SWITCH_PROVIDER = '@provider/switch-provider';

export function getPermittedProviders() {
  return async (dispatch) => {
    try {
      dispatch({ type: PROVIDERS_REQUEST });

      const providers = await providerService.getPermittedProviders();

      if (providers) {
        dispatch({
          type: PROVIDERS_SUCCESS,
          payload: {
            providers
          }
        });
      }
    } catch (error) {
      dispatch({ type: PROVIDERS_FAILURE });
      throw error;
    }
  };
}

export function setActiveProvider(provider) {
  return async (dispatch) => dispatch({
    type: SWITCH_PROVIDER,
    payload: {
      provider
    }
  });
}

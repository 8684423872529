import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 128,
    paddingBottom: 128
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular
  }
}));

function Publish({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="md">
        <Typography
          variant="h1"
          align="center"
          color="textPrimary"
        >
          Publish with Carry1st
        </Typography>
        <Box mt={3}>
          <Typography
            variant="body1"
            align="center"
            color="textPrimary"
          >
            We are the go-to growth partner for game developers looking to scale in Africa.
            Partner with us to leverage our unique user acquisition and monetization capabilities in the final growth
            market.
          </Typography>

        </Box>
      </Container>
    </div>
  );
}

Publish.propTypes = {
  className: PropTypes.string
};

export default Publish;

import http from 'src/http/HttpCommon';

class ProviderService {
  getProviders = async (page, size, orderBy, order, status, searchTerm, providerIds) => {
    try {
      const response = await http.get('/api/admin/providers', {
        headers: {
          'Content-Type': 'application/vnd.carry1st.products.provider+json'
        },
        data: {},
        params: {
          page,
          size,
          orderBy,
          order,
          status,
          searchTerm,
          providerIds
        }
      });

      return response.data;
    } catch (error) {
      return error.response;
    }
  };

  getProvidersByIds = async (providerIds) => {
    try {
      const response = await http.get('/api/admin/providers', {
        headers: {
          'Content-Type': 'application/vnd.carry1st.products.provider+json'
        },
        data: {},
        params: { providerIds: providerIds.reduce((f, s) => `${f},${s}`, '') }
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  };

  getPermittedProviders = async () => {
    try {
      const response = await http.get('/api/admin/providers/permitted', {
        headers: {
          'Content-Type': 'application/vnd.carry1st.products.provider+json'
        },
        data: {}
      });

      return response.data.providers;
    } catch (error) {
      return error.response;
    }
  };

  getProvider = async (id) => {
    try {
      const response = await http.get(`/api/admin/providers/${id}`, {
        headers: {
          'Content-Type': 'application/vnd.carry1st.products.provider+json'
        },
        data: {}
      });

      return response.data;
    } catch (error) {
      return error.response;
    }
  };

  addProvider = async (
    name,
    code,
    balance, threshold,
    fiatCurrencyId,
    status,
    highRisk,
    uploadReport,
    eligibleForVip,
    overrideDiscountPointRewardRate,
    discountPointRewardRate
  ) => {
    try {
      return http.post('/api/admin/providers', {
        name,
        code,
        balance,
        threshold,
        fiatCurrencyId,
        status,
        highRisk,
        uploadReport,
        eligibleForVip,
        overrideDiscountPointRewardRate,
        discountPointRewardRate,
      }, {
        headers: {
          'Content-Type': 'application/vnd.carry1st.products.provider+json'
        }
      });
    } catch (error) {
      return error.response;
    }
  };

  updateProvider = async (
    id,
    name,
    code,
    balance,
    threshold,
    fiatCurrencyId,
    status,
    highRisk,
    uploadReport,
    eligibleForVip,
    overrideDiscountPointRewardRate,
    discountPointRewardRate,
  ) => {
    try {
      return http.put(`/api/admin/providers/${id}`, {
        name,
        code,
        balance,
        threshold,
        fiatCurrencyId,
        status,
        highRisk,
        uploadReport,
        eligibleForVip,
        overrideDiscountPointRewardRate,
        discountPointRewardRate,
      }, {
        headers: {
          'Content-Type': 'application/vnd.carry1st.products.provider+json'
        }
      });
    } catch (error) {
      return error.response;
    }
  };

  toggleProviderStock = async (provider) => {
    try {
      return http.put(`/api/admin/providers/stock/${provider.id}`, {
        inStock: !provider.inStock
      }, {
        headers: {
          'Content-Type': 'application/vnd.carry1st.products.provider+json'
        }
      });
    } catch (error) {
      return error.response;
    }
  };

  deleteProvider = async (id) => {
    try {
      return http.delete(`/api/admin/providers/${id}`, {
        headers: {
          'Content-Type': 'application/vnd.carry1st.products.provider+json'
        },
        data: {}
      });
    } catch (error) {
      return error.response;
    }
  };
}

const providerService = new ProviderService();

export default providerService;

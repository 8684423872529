import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  Box,
  Container,
  Grid,
  Typography,
  makeStyles, colors
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.footer,
    color: colors.common.white,
    paddingTop: 40,
    paddingBottom: 40
  },
  button: {
    marginTop: 20,
    backgroundColor: theme.palette.primary.dark,
    color: colors.common.white
  },
  copyright: {
    marginTop: 40
  }
}));

function Features({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Box mt={8}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                  >
                    New York City, USA
                  </Typography>
                  <Typography
                    variant="body1"
                  >
                    122 West 27th Street
                    <br/>
                    New York City, NY
                    <br/>
                    10001
                    <br/>
                  </Typography>
                  <Button className={classes.button}
                    variant="outlined"
                    component="a"
                    href="https://goo.gl/maps/6xUu2rkhPTDvQsYE7"
                    target="_blank"
                  >
                    Directions
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                  >
                    Cape Town, South Africa
                  </Typography>
                  <Typography
                    variant="body1"
                  >
                    8 Bridgeway Road
                    <br/>
                    Century City, Cape Town
                    <br/>
                    7441
                    <br/>
                  </Typography>
                  <Button className={classes.button}
                    variant="outlined"
                    component="a"
                    href="https://goo.gl/maps/oa6oMNgn7Ucmdc4P7"
                    target="_blank"
                  >
                    Directions
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                  >
                    Lagos, Nigeria
                  </Typography>
                  <Typography
                    variant="body1"
                  >
                    360 Ikorodu Road
                    <br/>
                    Maryland, Lagos
                    <br/>
                    100211
                    <br/>
                  </Typography>
                  <Button className={classes.button}
                    variant="outlined"
                    component="a"
                    href="https://goo.gl/maps/cqRMs4VePsWuwmLy5"
                    target="_blank"
                  >
                    Directions
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Typography className={classes.copyright}
          variant="body2"
          align="center"
        >
          © 2019 Carry1st. All Rights Reserved.
        </Typography>
      </Container>
    </div>
  );
}

Features.propTypes = {
  className: PropTypes.string
};

export default Features;

/* eslint-disable import/prefer-default-export */
import {
  Aperture as ApertureIcon,
  Archive as CmsIcon,
  BarChart as BarChartIcon,
  Clock as SchedulersIcon,
  Circle as DiscountPointsIcon,
  DollarSign as DollarSignIcon,
  Globe as GlobeIcon,
  Home as HomeIcon,
  Gift as GiftIcon,
  ArrowUpCircle as ArrowUpCircleIcon,
  Divide as DivideIcon,
  HelpCircle as HelpCircleIcon,
  Layers as FulfilmentIcon,
  List as ProductCategoryIcon,
  DivideSquare as PayoutIcon,
  Map as MapIcon,
  PieChart as PieChartIcon,
  Package as GemsIcon,
  Tag as PromoCodeIcon,
  Activity as ServiceFeeIcon,
  Pocket as PromoIcon,
  CheckSquare as ChallengeIcon,
  Settings as SettingsIcon,
  ShoppingCart as ShoppingCartIcon,
  Users as UsersIcon,
  UserCheck as UserCheckIcon,
  Zap as SeoConfigsIcon,
  UserPlus as VipIcon,
  Divide as VipPointsIcon,
  ArrowUpCircle as VipLevelsIcon,
  Edit as ContentEditIcon,
  TrendingUp as StockLevelsIcon,
  Tablet as TabletIcon,
  Trash2,
  CreditCard as BlockedCardIcon,
  XOctagon as BlockedBinIcon,
  ShieldOff as BlockedPaymentDetailsIcon,
  Sliders as ConsoleSettingsIcon,
  Archive as ClassifcationModelIcon,
  Book as ApiDocsIcon,
  Bell as Notifications,
  Mail,
} from 'react-feather';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';

const menuIconMap = {
  adminUsers: '/static/images/menu/admin_users.svg',
  analysis: '/static/images/menu/analysis.svg',
  apiDocs: '/static/images/menu/APIDocs.svg',
  availablePaymentMethods: '/static/images/menu/available_payment_methods.svg',
  banners: '/static/images/menu/banners.svg',
  bundleGroups: '/static/images/menu/bundles.png',
  currencies: '/static/images/menu/currencies.svg',
  customers: '/static/images/menu/customers.svg',
  fulfilmentMethods: '/static/images/menu/fulfilment_methods.svg',
  home: '/static/images/menu/Home.png',
  ledger: '/static/images/menu/Ledger.svg',
  orders: '/static/images/menu/Orders.svg',
  payouts: '/static/images/menu/payouts.svg',
  myStore: '/static/images/menu/my_store.png',
  manualReview: '/static/images/menu/manual_review.svg',
  payments: '/static/images/menu/payments.png',
  new_payments: '/static/images/menu/new_payments.svg',
  paymentGateways: '/static/images/menu/payment_gateways.svg',
  paymentMethodGroups: '/static/images/menu/payment_method_groups.svg',
  paymentMethods: '/static/images/menu/payment_methods.svg',
  payoutMethods: '/static/images/menu/methods.svg',
  payoutsHistory: '/static/images/menu/payout_history.svg',
  products: '/static/images/menu/products.png',
  productFulfillmentMethods: '/static/images/menu/product_fulfillment_methods.svg',
  roles: '/static/images/menu/roles.png',
  settings: '/static/images/menu/settings.svg',
  systemStatus: '/static/images/menu/system_status.svg',
};

export const INTERNAL_NAV_CONFIG = [
  {
    subheader: 'Reports',
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/app/reports/dashboard'
      },
      {
        title: 'Financial Ledger',
        icon: DollarSignIcon,
        href: '/app/reports/ledger',
        permissions: ['VIEW_FINANCIAL_LEDGER', 'EXPORT_FINANCIAL_LEDGER']
      },
      {
        title: 'Order Items',
        icon: ShoppingCartIcon,
        href: '/app/reports/order-items',
        permissions: ['VIEW_ORDER_ITEMS_HISTORY', 'EDIT_ORDER_ITEMS_HISTORY']
      },
      {
        title: 'Transaction History',
        icon: BarChartIcon,
        href: '/app/reports/transactions',
        permissions: ['VIEW_TRX_HISTORY', 'EDIT_TRX_HISTORY']
      },
      {
        title: 'Fulfilment History',
        icon: FulfilmentIcon,
        href: '/app/reports/fulfilments',
        permissions: ['VIEW_FULFILLMENT_HISTORY', 'EXPORT_FULFILLMENT_HISTORY']
      },
      {
        title: 'Payouts',
        icon: PayoutIcon,
        items: [
          {
            title: 'Payouts History',
            icon: menuIconMap.payoutsHistory,
            href: '/app/reports/payouts',
            permissions: ['VIEW_PAYOUTS_HISTORY', 'EXPORT_PAYOUTS_HISTORY']
          },
          {
            title: 'Payout Methods',
            icon: menuIconMap.payoutMethods,
            href: '/app/reports/payouts/methods',
            permissions: ['VIEW_PAYOUTS_HISTORY', 'EXPORT_PAYOUTS_HISTORY']
          },
          {
            title: 'Manual Review',
            icon: menuIconMap.manualReview,
            href: '/app/reports/payouts/tiered',
            permissions: ['VIEW_PAYOUTS_HISTORY', 'EXPORT_PAYOUTS_HISTORY']
          },
        ]
      },
      {
        title: 'Gems',
        icon: GemsIcon,
        href: '/app/reports/gems',
        permissions: ['VIEW_GEMS_HISTORY', 'EXPORT_GEMS_HISTORY']
      },
      {
        title: 'Discount Points',
        icon: DiscountPointsIcon,
        href: '/app/reports/discount-points/transactions',
        permissions: ['VIEW_DISCOUNT_POINTS', 'EDIT_DISCOUNT_POINTS']
      },
      {
        title: 'VIP',
        icon: VipIcon,
        items: [
          {
            title: 'VIP Points History',
            icon: VipPointsIcon,
            href: '/app/reports/vip/points',
            permissions: ['VIEW_VIP_SYSTEM', 'EXPORT_VIP_REPORTS']
          },
          {
            title: 'VIP Levels History',
            icon: VipLevelsIcon,
            href: '/app/reports/vip/levels',
            permissions: ['VIEW_VIP_SYSTEM', 'EXPORT_VIP_REPORTS']
          }
        ]
      }
    ]
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Notifications',
        icon: Notifications,
        href: '/app/notifications',
        // to change after we add permission to DB
        permissions: ['VIEW_COUNTRIES']
      },
      {
        title: 'Global Configurations',
        icon: GlobeIcon,
        items: [
          {
            title: 'Countries',
            icon: GlobeIcon,
            href: '/app/countries',
            permissions: ['VIEW_COUNTRIES', 'EDIT_COUNTRIES']
          },
          {
            title: 'Country Groups',
            icon: ApertureIcon,
            href: '/app/country-groups',
            permissions: ['VIEW_COUNTRIES', 'EDIT_COUNTRIES']
          },
          {
            title: 'Currencies',
            href: '/app/currencies/fiat',
            icon: menuIconMap.currencies,
            permissions: ['VIEW_FIAT_CURRENCIES', 'EDIT_FIAT_CURRENCIES']
          },
          {
            title: 'Exchange Rates',
            href: '/app/exchange-rates',
            icon: MapIcon,
            permissions: ['EDIT_EXCHANGE_RATES']
          },
          {
            title: 'Indirect Taxes',
            href: '/app/taxes',
            icon: MapIcon,
            permissions: ['EDIT_INDIRECT_TAXES']
          },
        ]
      },
      {
        title: 'Providers',
        href: '/app/providers',
        icon: HomeIcon,
        permissions: ['VIEW_PROVIDERS', 'EDIT_PROVIDERS']
      },
      {
        title: 'Products',
        icon: ShoppingCartIcon,
        items: [
          {
            title: 'Products',
            href: '/app/products',
            icon: ShoppingCartIcon,
            permissions: ['VIEW_PRODUCTS', 'EDIT_PRODUCTS']
          },
          {
            title: 'Bundle Groups',
            href: '/app/bundlegroups',
            icon: menuIconMap.bundleGroups,
            permissions: ['VIEW_BUNDLE_GROUPS', 'EDIT_BUNDLE_GROUPS']
          },
          {
            title: 'Promotions',
            href: '/app/promos',
            icon: PromoIcon,
            permissions: ['VIEW_PROMOS', 'EDIT_PROMOS']
          }
        ]
      },
      {
        title: 'Service Fees',
        href: '/app/service-fees',
        icon: ServiceFeeIcon,
        permissions: ['VIEW_FEES', 'EDIT_FEES']
      },
      {
        title: 'Promo Codes',
        icon: PromoCodeIcon,
        items: [
          {
            title: 'Promo Codes',
            icon: PromoCodeIcon,
            href: '/app/promo-codes',
            permissions: ['VIEW_PROMOS', 'EDIT_PROMOS']
          },
          {
            title: 'Promo Code Groups',
            icon: PromoCodeIcon,
            href: '/app/promo-code-groups',
            permissions: ['VIEW_PROMOS', 'EDIT_PROMOS']
          }
        ]
      },
      {
        title: 'VIP',
        icon: GiftIcon,
        items: [
          {
            title: 'Levels & Rewards',
            href: '/app/vip/levels',
            icon: ArrowUpCircleIcon,
            permissions: ['VIEW_VIP_SYSTEM', 'EDIT_VIP_SYSTEM']
          },
          {
            title: 'Points',
            href: '/app/vip/points',
            icon: DivideIcon,
            permissions: ['VIEW_VIP_SYSTEM', 'EDIT_VIP_SYSTEM']
          },
          {
            title: 'Promotions',
            href: '/app/vip/promos',
            icon: PromoIcon,
            permissions: ['VIEW_VIP_SYSTEM', 'EDIT_VIP_SYSTEM']
          },
          {
            title: 'Challenges',
            href: '/app/vip/challenges',
            icon: ChallengeIcon,
            permissions: ['VIEW_VIP_SYSTEM', 'EDIT_VIP_SYSTEM']
          },
          {
            title: 'FAQs',
            href: '/app/vip/faqs',
            icon: HelpCircleIcon,
            permissions: ['VIEW_VIP_SYSTEM', 'EDIT_VIP_SYSTEM']
          }
        ]
      },
      {
        title: 'Discount Points',
        icon: DiscountPointsIcon,
        items: [
          {
            title: 'FAQs',
            href: '/app/discount-points/faqs',
            icon: HelpCircleIcon,
            permissions: ['VIEW_DISCOUNT_POINTS', 'EDIT_DISCOUNT_POINTS']
          }
        ]
      },
      {
        title: 'Shop Configs',
        icon: CmsIcon,
        items: [
          {
            title: 'Categories',
            href: '/app/product-categories',
            icon: ProductCategoryIcon,
            permissions: ['VIEW_PRODUCT_CATEGORIES', 'EDIT_PRODUCT_CATEGORIES']
          },
          {
            title: 'Banners',
            href: '/app/banners',
            icon: menuIconMap.banners,
            permissions: ['VIEW_BANNERS', 'EDIT_BANNERS']
          },
          {
            title: 'Propositions',
            href: '/app/propositions',
            icon: menuIconMap.apiDocs,
          },
          {
            title: 'Static Content',
            href: '/app/content',
            icon: ContentEditIcon,
            permissions: ['VIEW_PRODUCTS', 'EDIT_PRODUCTS']
          },
          {
            title: 'SEO Configs',
            href: '/app/product-meta',
            icon: SeoConfigsIcon,
            permissions: ['VIEW_PRODUCTS', 'EDIT_PRODUCTS']
          }
        ]
      },
      {
        title: 'Dynamic Content',
        icon: ContentEditIcon,
        items: [
          {
            title: 'Products',
            href: '/app/dynamic-content/products',
            icon: ShoppingCartIcon,
          },
        ]
      },
      {
        title: 'Payments',
        icon: ReceiptIcon,
        items: [
          {
            title: 'Payment Gateways',
            href: '/app/payments/gateways',
            icon: menuIconMap.paymentGateways,
            permissions: ['EDIT_PAYMENTS']
          },
          {
            title: 'Available Payment Methods',
            href: '/app/payments/available-methods',
            icon: menuIconMap.availablePaymentMethods,
            permissions: ['EDIT_PAYMENTS']
          },
          {
            title: 'Payment Methods',
            href: '/app/payments/methods',
            icon: menuIconMap.paymentMethods,
            permissions: ['EDIT_PAYMENTS']
          },
          {
            title: 'Payment Method Groups',
            href: '/app/payments/methods/groups',
            icon: menuIconMap.paymentMethodGroups,
            permissions: ['EDIT_PAYMENTS']
          },
          {
            title: 'Blocked Cards',
            href: '/app/payments/blocked-cards',
            icon: BlockedCardIcon,
            permissions: ['EDIT_PAYMENTS']
          },
          {
            title: 'Blocked BINs',
            href: '/app/payments/blocked-bins',
            icon: BlockedBinIcon,
            permissions: ['EDIT_PAYMENTS']
          },
          {
            title: 'Blocked Payment Details',
            href: '/app/payments/blocked-payment-details',
            icon: BlockedPaymentDetailsIcon,
            permissions: ['EDIT_PAYMENTS']
          },
          {
            title: 'Payment Classification',
            href: '/app/payment-classification',
            icon: ClassifcationModelIcon,
            permissions: ['EDIT_PAYMENTS']
          },
        ]
      },
      {
        title: 'Fulfilments',
        icon: FulfilmentIcon,
        items: [
          {
            title: 'Fulfilment Methods',
            href: '/app/fulfilments/fulfilment-methods',
            icon: menuIconMap.fulfilmentMethods,
            permissions: ['EDIT_FULFILLMENT']
          },
          {
            title: 'Product Fulfilment Methods',
            href: '/app/fulfilments/product-fulfilment-methods',
            icon: menuIconMap.productFulfillmentMethods,
            permissions: ['EDIT_FULFILLMENT']
          },
          {
            title: 'Manual Fulfilments',
            href: '/app/fulfilments/manual-fulfilment',
            icon: menuIconMap.productFulfillmentMethods,
            permissions: ['VIEW_ORDER_ITEMS_HISTORY', 'EDIT_ORDER_ITEMS_HISTORY', 'EXPORT_ORDER_ITEMS_HISTORY']
          }
        ]
      },
      {
        title: 'Stock Management',
        icon: CmsIcon,
        items: [
          {
            title: 'Stock Providers',
            icon: HomeIcon,
            href: '/app/stock-management/providers',
            permissions: ['VIEW_STOCK', 'EDIT_STOCK']
          },
          {
            title: 'Stock Inventory',
            icon: CmsIcon,
            href: '/app/stock-management/inventory',
            permissions: ['VIEW_STOCK', 'EDIT_STOCK']
          },
          {
            title: 'Stock Levels',
            icon: StockLevelsIcon,
            href: '/app/stock-management/stock-levels',
            permissions: ['VIEW_STOCK', 'EDIT_STOCK']
          }
        ]
      },
      {
        title: 'Gem Fees',
        icon: GemsIcon,
        permissions: ['EDIT_GEMS_FEES'],
        href: '/app/gems/gem-fees'
      },
      {
        title: 'Users',
        icon: UsersIcon,
        items: [
          {
            title: 'Customers',
            href: '/app/customers',
            icon: menuIconMap.customers,
            permissions: ['EDIT_CUSTOMERS']
          },
          {
            title: 'Admin Panel Users',
            href: '/app/admin/users',
            icon: menuIconMap.adminUsers,
            permissions: ['EDIT_ADMIN_USERS']
          }
        ]
      }
    ]
  },
  {
    subheader: 'Settings',
    items: [
      {
        title: 'Schedulers',
        href: '/app/settings/schedulers',
        icon: SchedulersIcon,
        permissions: ['EDIT_SETTINGS']
      },
      {
        title: 'Phone Verification',
        icon: Mail,
        permissions: ['VIEW_OTP_METHODS', 'EDIT_OTP_METHODS'],
        href: '/app/otp-methods'
      },
      {
        title: 'Configuration',
        href: '/app/settings/configuration',
        icon: SettingsIcon,
        permissions: ['EDIT_SETTINGS']
      },
      {
        title: 'Clear Cache',
        href: '/app/settings/clearcache',
        icon: Trash2,
        permissions: ['EDIT_SETTINGS']
      },
      {
        title: 'Permissions',
        href: '/app/settings/permissions',
        icon: UserCheckIcon,
        permissions: ['EDIT_SETTINGS']
      },
      {
        title: 'Roles',
        href: '/app/settings/roles',
        icon: SchedulersIcon,
        permissions: ['EDIT_SETTINGS']
      },
      {
        title: 'App Versions',
        href: '/app/settings/app-versions',
        icon: TabletIcon,
        permissions: ['EDIT_SETTINGS']
      },
      {
        title: 'Console Settings',
        href: '/app/settings/console-settings',
        icon: ConsoleSettingsIcon
      },
      {
        title: 'API Docs',
        href: 'https://carry1st-platform.readme.io/reference/pay1st-introduction',
        icon: ApiDocsIcon,
        external: true
      },
      {
        title: 'Systems Status',
        href: 'https://pay1st.statuspage.io/#',
        icon: menuIconMap.systemStatus,
        external: true
      },
    ]
  }
];

export const EXTERNAL_NAV_CONFIG = [
  {
    subheader: '',
    items: [
      {
        title: 'Home',
        icon: menuIconMap.home,
        href: '/app/reports/home',
        permissions: ['VIEW_TRX_HISTORY', 'VIEW_ANALYTICS']
      },
      {
        title: 'Payments',
        icon: menuIconMap.payments,
        href: '/app/reports/payments',
        permissions: ['VIEW_TRX_HISTORY']
      },
      {
        title: 'Orders',
        icon: menuIconMap.orders,
        href: '/app/reports/order-items',
        permissions: ['VIEW_ORDER_ITEMS_HISTORY', 'EDIT_ORDER_ITEMS_HISTORY']
      },
      {
        title: 'Analytics',
        icon: menuIconMap.analysis,
        href: '/app/reports/analytics',
        permissions: ['VIEW_ANALYTICS']
      },
      {
        title: 'Countries',
        icon: GlobeIcon,
        href: '/app/countries',
        permissions: ['VIEW_COUNTRIES', 'EDIT_COUNTRIES']
      },
      {
        title: 'Customer Payouts',
        icon: PayoutIcon,
        href: '/app/reports/payouts',
        permissions: ['VIEW_PAYOUTS_HISTORY', 'EXPORT_PAYOUTS_HISTORY']
      },
      // temporarily removed Balances page as it's currenlty not functional
      // {
      //   title: 'Balances',
      //   icon: PayoutIcon,
      //   href: '/app/reports/balances',
      //   permissions: ['VIEW_BALANCES']
      // },
      {
        title: 'My Store',
        icon: menuIconMap.myStore,
        items: [
          {
            title: 'Products',
            href: '/app/products',
            icon: menuIconMap.products,
            permissions: ['VIEW_PRODUCTS', 'EDIT_PRODUCTS']
          },
          {
            title: 'Bundle Groups',
            href: '/app/bundlegroups',
            icon: menuIconMap.bundleGroups,
            permissions: ['VIEW_BUNDLE_GROUPS', 'EDIT_BUNDLE_GROUPS']
          },
          {
            title: 'Promotions',
            href: '/app/promos',
            icon: PromoIcon,
            permissions: ['VIEW_PROMOS', 'EDIT_PROMOS']
          }
        ]
      },
      {
        title: 'My Team',
        icon: UsersIcon,
        items: [
          {
            title: 'Users',
            href: '/app/admin/users',
            icon: menuIconMap.adminUsers,
            permissions: ['VIEW_BALANCES']
          },
          {
            title: 'Roles',
            href: '/app/settings/roles',
            icon: menuIconMap.roles,
            permissions: ['EDIT_ROLES']
          },
        ]
      },
      {
        title: 'API Docs',
        href: 'https://carry1st-platform.readme.io/reference/pay1st-introduction',
        icon: menuIconMap.apiDocs,
        external: true
      },
      {
        title: 'Systems Status',
        href: 'https://pay1st.statuspage.io/#',
        icon: menuIconMap.systemStatus,
        external: true
      },
    ]
  }
];

import http from 'src/http/HttpCommon';

class SettingsService {
  getSettingById = async (id) => {
    try {
      const response = await http.get(`/api/admin/settings/${id}`, {
        headers: {
          'Content-Type': 'application/vnd.carry1st.settings.setting+json'
        },
        data: {}
      });

      return response.data;
    } catch (error) {
      return error.response;
    }
  };

  getSettings = async (page, size, orderBy, order, type, searchTerm) => {
    try {
      const response = await http.get('/api/admin/settings', {
        headers: {
          'Content-Type': 'application/vnd.carry1st.settings.setting+json'
        },
        data: {},
        params: {
          page,
          size,
          orderBy,
          order,
          type,
          searchTerm
        }
      });

      return response.data;
    } catch (error) {
      return error.response;
    }
  };

  updateSettings = async ({
    id,
    type,
    value,
    failoverValue,
    supportedCountryCodes,
    supportedCustomerIdCountryCodes,
    customerIdSuffix,
    description
  }) => {
    try {
      return await http.put(
        `/api/admin/settings/${id}`,
        {
          type,
          value,
          failoverValue,
          supportedCountryCodes,
          supportedCustomerIdCountryCodes,
          customerIdSuffix,
          description
        },
        {
          headers: {
            'Content-Type': 'application/vnd.carry1st.settings.setting+json'
          }
        }
      );
    } catch (error) {
      return error.response;
    }
  };
}

const settingsService = new SettingsService();

export default settingsService;

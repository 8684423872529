import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Title from './Title';
import Features from './Features';
import Publish from './Publish';
import Footer from './Footer'

const useStyles = makeStyles(() => ({
  root: {}
}));

function HomeView() {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Home"
    >
      <Title />
      <Features />
      <Publish />
      <Footer />
    </Page>
  );
}

export default HomeView;

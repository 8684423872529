import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios';
import {
  isAndroid,
  isIOS,
  mobileVendor,
  mobileModel,
  osName,
  osVersion,
  browserName,
  fullBrowserVersion
} from 'react-device-detect';
import http from 'src/http/HttpCommon';
import Cookies from 'js-cookie';

const isCookieExpired = (cookieName) => (!Cookies.get(cookieName));

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    const handleUnauthorizedError = (error) => {
      if (error.response && error.response.status === 401) {
        this.setSession(null);

        if (onLogout) {
          onLogout();
        }
      }
    };

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        handleUnauthorizedError(error);

        return Promise.reject(error);
      }
    );

    http.interceptors.response.use(
      (response) => response,
      (error) => {
        handleUnauthorizedError(error);

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  loginWithEmailAndPassword = (email, password) => new Promise((resolve, reject) => {
    http.post('/api/admin/accounts/login', {
      email,
      password,
      // eslint-disable-next-line no-nested-ternary
      platform: isAndroid === true ? 'AND' : (isIOS === true ? 'IOS' : 'WEB'),
      manufacturer: mobileVendor,
      model: mobileModel,
      osVersion: `${osName} ${osVersion}`,
      browserName,
      browserVersion: fullBrowserVersion
    }, {
      headers: {
        'Content-Type': 'application/vnd.carry1st.accounts.authentication+json'
      }
    }).then((response) => {
      if (response.data.authToken) {
        const expires = new Date(new Date().getTime() + 15 * 60 * 1000);
        this.setSession(response.data.authToken);
        Cookies.set('token', response.data.authToken, { expires, secure: true });
        resolve({
          ...response.data.profile,
          ...{ passwordExpired: response.data.passwordExpired }
        });
      } else if (response.data.mfa) {
        resolve({
          ...response.data
        });
      } else {
        reject(response.data);
      }
    }).catch((error) => {
      reject(error.response.data);
    });
  });

  loginInWithToken = () => new Promise((resolve, reject) => {
    if (!isCookieExpired('token')) {
      http.get('/api/admin/accounts/login', {
        headers: {
          'Content-Type': 'application/vnd.carry1st.accounts.authentication+json'
        },
        data: {}
      }).then((response) => {
        if (response.data.authToken) {
          this.setSession(response.data.authToken);
          resolve({
            ...response.data.profile,
            ...{ passwordExpired: response.data.passwordExpired }
          });
        } else {
          reject(response.error);
          this.logout();
        }
      })
        .catch((error) => {
          this.logout();
          reject(error);
        });
    } else {
      this.logout();
    }
  });

  logout = () => {
    this.setSession(null);
  };

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
    } else {
      localStorage.removeItem('accessToken');
    }
  };

  getAccessToken = () => localStorage.getItem('accessToken');

  forgotPassword = async (email) => {
    try {
      return await http.get('/api/admin/accounts/password/forgot', {
        headers: {
          'Content-Type': 'application/vnd.carry1st.accounts.authentication+json'
        },
        data: {},
        params: { email }
      });
    } catch (error) {
      return error.response;
    }
  };

  completeRegistration = async (password, hash) => {
    try {
      const response = await http.post('/api/admin/accounts/invite/accept', {
        password,
        hash,
        // eslint-disable-next-line no-nested-ternary
        platform: isAndroid === true ? 'AND' : (isIOS === true ? 'IOS' : 'WEB'),
        manufacturer: mobileVendor,
        model: mobileModel,
        osVersion: `${osName} ${osVersion}`,
        browserName,
        browserVersion: fullBrowserVersion
      }, {
        headers: {
          'Content-Type': 'application/vnd.carry1st.accounts.authentication+json'
        }
      });
      if (response.data.authToken) {
        this.setSession(response.data.authToken);
      }
      return response;
    } catch (error) {
      return error.response;
    }
  };

  getRolePermissions = async (roleId) => {
    const response = await http.get(`/api/admin/accounts/roles/${roleId}`, {
      headers: {
        'Content-Type': 'application/vnd.carry1st.accounts.role+json'
      },
      data: {}
    });

    return response.data;
  }

  resetPassword = async (password, hash) => {
    try {
      const response = await http.put('/api/admin/accounts/password/forgot', {
        password,
        hash,
        // eslint-disable-next-line no-nested-ternary
        platform: isAndroid === true ? 'AND' : (isIOS === true ? 'IOS' : 'WEB'),
        manufacturer: mobileVendor,
        model: mobileModel,
        osVersion: `${osName} ${osVersion}`,
        browserName,
        browserVersion: fullBrowserVersion
      }, {
        headers: {
          'Content-Type': 'application/vnd.carry1st.accounts.authentication+json'
        }
      });
      if (response.data.authToken) {
        this.setSession(response.data.authToken);
      }
      return response;
    } catch (error) {
      return error.response;
    }
  };

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  verifyPassword = async (password) => {
    const response = await http.post('/api/admin/accounts/login/verify', { password }, {
      headers: {
        'Content-Type': 'application/vnd.carry1st.accounts.authentication+json'
      }
    });

    return response.data;
  };

  isAuthenticated = () => !!this.getAccessToken();

  registerMFAToken = (token, trustDevice, sessionKey) => new Promise((resolve, reject) => {
    http.post('/api/admin/accounts/mfa/register', {
      token,
      trustDevice
    }, {
      headers: {
        'Content-Type': 'application/vnd.carry1st.accounts.authentication+json',
        'X-SESSION-KEY': sessionKey
      }
    }).then((response) => {
      if (response.data.authToken) {
        const expires = new Date(new Date().getTime() + 15 * 60 * 1000);
        this.setSession(response.data.authToken);
        Cookies.set('token', response.data.authToken, { expires, secure: true });
        resolve({
          ...response.data.profile,
          ...{ passwordExpired: response.data.passwordExpired }
        });
      } else if (response.data.mfa) {
        resolve({
          ...response.data
        });
      } else {
        reject(response.data);
      }
    }).catch((error) => {
      reject(error.response.data);
    });
  });

  verifyMFAToken = (token, trustDevice, sessionKey) => new Promise((resolve, reject) => {
    http.post('/api/admin/accounts/mfa/verify', {
      token,
      trustDevice
    }, {
      headers: {
        'Content-Type': 'application/vnd.carry1st.accounts.authentication+json',
        'X-SESSION-KEY': sessionKey
      }
    }).then((response) => {
      if (response.data.authToken) {
        const expires = new Date(new Date().getTime() + 15 * 60 * 1000);
        this.setSession(response.data.authToken);
        Cookies.set('token', response.data.authToken, { expires, secure: true });
        resolve({
          ...response.data.profile,
          ...{ passwordExpired: response.data.passwordExpired }
        });
      } else if (response.data.mfa) {
        resolve({
          ...response.data
        });
      } else {
        reject(response.data);
      }
    }).catch((error) => {
      reject(error.response.data);
    });
  });
}

const authService = new AuthService();

export default authService;

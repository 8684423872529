import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import PropTypes from 'prop-types';
import SplashScreen from './SplashScreen';

function GuestGuard({ children }) {
  const { push } = useHistory();
  const location = useLocation();
  const account = useSelector((state) => state.account);
  const [isLoading, setLoading] = useState(false);
  const { activeProvider } = useSelector((state) => state.provider);

  const performRedirect = useCallback(async () => {
    setLoading(true);
    if (account.user.type === 'INTERNAL') {
      push('/app/reports/dashboard');
    } else {
      push('/app/reports/home');
    }
  }, [account, activeProvider, push]);

  const performMfaRedirect = useCallback(async () => {
    setLoading(false);
    if (account.mfa.status === 'UNVERIFIED') {
      push('/mfa-register');
    } else {
      push('/mfa-verify');
    }
  }, [account, push]);

  useEffect(() => {
    if (activeProvider && account.user) {
      performRedirect();
    } else if (account && account.mfa) {
      performMfaRedirect();
    } else if (location.pathname === '/mfa-register' || location.pathname === '/mfa-verify') {
      push('/login');
    } else {
      push(location.pathname + location.search);
    }
  }, [account, activeProvider, performRedirect, performMfaRedirect, push]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

GuestGuard.propTypes = {
  children: PropTypes.any
};

export default GuestGuard;

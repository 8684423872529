import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import { logout } from 'src/store/actions/accountActions';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { EXTERNAL_NAV_CONFIG, INTERNAL_NAV_CONFIG } from './NavBar/NavConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  fullWrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

function DashboardLayout({ hideNavigation, children }) {
  const classes = useStyles();
  const account = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const timeout = process.env.REACT_APP_SESSION_TIMEOUT;
  const promptTimeout = process.env.REACT_APP_SESSION_PROMPT_TIMEOUT;

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [remaining, setRemaining] = useState(0);

  const handleOnPrompt = () => {
    setOpen(true);
    setRemaining(promptTimeout);
  };

  const handleOnIdle = async () => {
    try {
      setOpen(false);
      setRemaining(0);
      await dispatch(logout());
      push('/');
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  const handleOnActive = () => {
    setOpen(false);
    setRemaining(0);
  };

  const { getRemainingTime, isPrompted, reset } = useIdleTimer({
    timeout,
    promptTimeout,
    onPrompt: handleOnPrompt,
    onIdle: handleOnIdle,
    onActive: handleOnActive
  });

  const handleExtendSession = () => {
    setOpen(false);
    reset();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        const secondsLeft = Math.ceil(getRemainingTime() / 1000);
        setRemaining(secondsLeft);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted, remaining]);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      {!hideNavigation && (
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
          account={account}
          navConfig={account.user?.type === 'INTERNAL' ? INTERNAL_NAV_CONFIG : EXTERNAL_NAV_CONFIG}
        />
      )}
      <div className={hideNavigation ? classes.fullWrapper : classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={clsx(classes.content, 'scrollbarContainer')}>
            <>
              <ConfirmationDialog
                openDialog={open}
                onClose={handleOnIdle}
                onClick={handleExtendSession}
                title="Your session is expiring"
                message={`Logging you out in ${remaining} seconds`}
                confirmLabel="Extend the session"
                cancelLabel="Logout"
              />
              {children}
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any,
  hideNavigation: PropTypes.bool,
};

export default DashboardLayout;

import React, { useCallback, useState } from 'react';

import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import deepEqual from 'deep-equal';

import { selectAccount } from 'src/store/selectors/accountSelectors';
import PasswordDialog from './PasswordDialog';

const PasswordExpiredDialog = () => {
  const { user } = useSelector(selectAccount, deepEqual);
  const { push } = useHistory();
  const [isOpen, setIsOpen] = useState(localStorage.getItem('showPasswordExpired') === 'true');
  const passwordExpired = user?.passwordExpired;
  const close = useCallback(() => {
    localStorage.setItem('showPasswordExpired', 'false');
    setIsOpen(false);
  }, []);

  const changePassword = useCallback(() => {
    localStorage.setItem('showPasswordExpired', 'false');
    setIsOpen(false);
    push('/app/account/change-password');
  }, [push]);

  if (passwordExpired && localStorage.getItem('showPasswordExpired') === null) {
    localStorage.setItem('showPasswordExpired', 'true');
    setIsOpen(true);
  }

  if (!user) {
    localStorage.removeItem('showPasswordExpired');
    return null;
  }

  return (
    <PasswordDialog
      isOpen={isOpen === true}
      title="Change Password"
      message="Your password has expired. To optimize the security of your account, please change your password."
      confirmLabel="Change Password"
      cancelLabel="Ignore"
      onConfirm={() => changePassword()}
      onClose={() => close()}
    />
  );
};

export default PasswordExpiredDialog;

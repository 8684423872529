import React, {
  useRef,
  useState,
  useCallback
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Avatar,
  Box,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Typography,
  makeStyles,
  Hidden,
  Tooltip
} from '@material-ui/core';
import OnlineIndicator from 'src/components/OnlineIndicator';
import PropTypes from 'prop-types';
import { setActiveProvider } from 'src/store/actions/providerActions';
import { setActivePermissions } from 'src/store/actions/accountActions';
import getInitials from 'src/utils/getInitials';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320,
    padding: theme.spacing(2)
  },
  list: {
    padding: theme.spacing(1, 3)
  },
  listItemText: {
    marginRight: theme.spacing(1)
  },
  lastActivity: {
    whiteSpace: 'nowrap'
  }
}));

function ProviderRow({ provider, activeProvider, onClick }) {
  const classes = useStyles();

  const handleRowClick = useCallback(() => onClick(provider), [onClick, provider]);

  return (
    <ListItem
      disableGutters
      key={provider.id}
      onClick={handleRowClick}
    >
      <ListItemAvatar>
        <Avatar
          alt=""
          src={provider.avatar}
        >
          {getInitials(provider.name)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        className={classes.listItemText}
        disableTypography
        primary={(
          <Link
            color="textPrimary"
            component={RouterLink}
            display="block"
            underline="none"
            noWrap
            to="#"
            variant="h6"
          >
            {provider.name}
          </Link>
        )}
      />
      {provider.id === activeProvider.id && (
        <OnlineIndicator
          size="medium"
          status="online"
        />
      )}
    </ListItem>
  );
}

ProviderRow.propTypes = {
  provider: PropTypes.object.isRequired,
  activeProvider: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

function Providers() {
  const classes = useStyles();
  const { push } = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { providers } = useSelector((state) => state.provider);
  const providersLength = providers?.length;
  const { activeProvider } = useSelector((state) => state.provider);
  const [isOpen, setOpen] = useState(false);
  const handleOpen = useCallback(() => {
    if (providersLength > 1) {
      setOpen(true);
    }
  }, [providersLength]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSwitchProvider = useCallback(async (provider) => {
    setOpen(false);
    dispatch(setActiveProvider(provider));
    dispatch(setActivePermissions(provider));
    push('/app');
  }, [dispatch, push]);

  if (!providers) {
    return null;
  }

  return (
    <>
      <Tooltip title="Providers">
        <Box
          display="flex"
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
          marginLeft="1.5em"
          marginRight="1.5em"
          onClick={handleOpen}
          ref={ref}
        >
          <img
            style={{ marginRight: '4px' }}
            alt="Logo"
            src="/static/images/menu/provider.png"
            width="32"
            height="32"
          />
          <Hidden smDown>
            <Typography
              variant="h6"
              color="inherit"
            >
              {`${activeProvider.name}`}
            </Typography>
          </Hidden>
        </Box>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography
          variant="h4"
          color="textPrimary"
        >
          Providers
        </Typography>
        <Box mt={2}>
          <List disablePadding>
            {providers && providers.map((provider) => (
              <ProviderRow
                provider={provider}
                activeProvider={activeProvider}
                key={provider.id}
                onClick={handleSwitchProvider}
              />
            ))}
          </List>
        </Box>
      </Popover>
    </>
  );
}

export default Providers;

/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box, Drawer, Hidden, List, ListSubheader, makeStyles
} from '@material-ui/core';
import settingsService from 'src/services/settingsService';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';

const renderNavItems = ({ items, pathname, depth }) => (
  <List disablePadding>
    {items.reduce(
      (acc, item) => reduceChildRoutes({
        acc,
        item,
        pathname,
        depth
      }),
      []
    )}
  </List>
);

const filterItemsByPermissions = (items, account) => items.reduce((acc, item) => {
  if (item.items) {
    const filteredChildren = filterItemsByPermissions(item.items, account);
    return filteredChildren.length > 0 ? [...acc, { ...item, items: filteredChildren }] : acc;
  }

  const showItem = Array.isArray(item.permissions)
    ? account.permissions.some((row) => item.permissions.includes(row))
    : true;
  return showItem ? [...acc, item] : acc;
}, []);

const reduceChildRoutes = ({
  acc, pathname, item, depth = 0
}) => {
  const key = item.title + depth;
  const hasChildren = item.items;

  const isOpen = hasChildren
    && item.items.some((navChildItem) => matchPath(pathname, {
      path: navChildItem.href,
      exact: false
    }));

  const navItem = (
    <NavItem
      depth={depth}
      icon={item.icon}
      key={key}
      info={item.info}
      title={item.title}
      external={item.external}
      display="flex"
      {...(hasChildren ? { open: Boolean(isOpen) } : { href: item.href })}
    >
      {hasChildren
        && renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
    </NavItem>
  );

  return [...acc, navItem];
};

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({
  openMobile, onMobileClose, account, navConfig
}) => {
  const [displayedMenu, setDisplayedMenu] = useState([]);
  const [displayDiscountPoints, setDisplayDiscountPoints] = useState(false);
  const classes = useStyles();
  const { pathname } = useLocation();

  const loadSettings = useCallback(async () => {
    const data = await settingsService.getSettings(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
    if (data.settings) {
      setDisplayDiscountPoints(data.settings[0].value);
    }
  }, []);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    loadSettings();
  }, [loadSettings]);

  const removeDiscountPointsNavs = () => navConfig.map((elem) => {
    if (elem.subheader === 'Reports' || elem.subheader === 'Management') {
      return {
        ...elem,
        items: [...elem.items.filter((item) => item.title !== 'Discount Points')]
      };
    } return elem;
  });

  useEffect(() => {
    setDisplayedMenu(
      displayDiscountPoints === 'false'
        ? filterItemsByPermissions(removeDiscountPointsNavs(), account)
        : filterItemsByPermissions(navConfig, account)
    );
  }, [account, navConfig, displayDiscountPoints]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to={account.user?.type === 'INTERNAL' ? '/app/reports/dashboard' : '/app/reports/home'}>
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          {displayedMenu.map((menuItem) => (
            <List
              key={menuItem.subheader}
              subheader={(
                <ListSubheader disableGutters disableSticky>
                  {menuItem.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: menuItem.items, pathname })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  account: PropTypes.object.isRequired
};

export default NavBar;

import http from 'src/http/HttpCommon';

class AccountService {
  changePassword = async (currentPassword, newPassword) => {
    try {
      return await http.put('/api/admin/accounts/profile/password', { currentPassword, newPassword }, {
        headers: {
          'Content-Type': 'application/vnd.carry1st.accounts.profile+json'
        }
      });
    } catch (error) {
      return error.response;
    }
  };

  getAdminUsers = async (
    page, size, orderBy, order, providerId, roleId, status, type, searchTerm) => {
    try {
      const response = await http.get('/api/admin/accounts/users', {
        headers: {
          'Content-Type': 'application/vnd.carry1st.accounts.adminuser+json'
        },
        data: {},
        params: {
          page,
          size,
          orderBy,
          order,
          providerId,
          roleId,
          status,
          type,
          searchTerm
        }
      });

      return response.data;
    } catch (error) {
      return error.response;
    }
  };

  getAdminUser = async (id) => {
    try {
      const response = await http.get(`/api/admin/accounts/users/${id}`, {
        headers: {
          'Content-Type': 'application/vnd.carry1st.accounts.adminuser+json'
        },
        data: {}
      });

      const providerRoles = [];
      if (response.data.roles) {
        response.data.roles.map((item) => {
          providerRoles.push({ providerId: item.providerId, roleId: item.role.id });
          return item;
        });
      }
      response.data.providerRoles = providerRoles;
      return response.data;
    } catch (error) {
      return error.response;
    }
  };

  updateAdminUser = async (id, firstName, lastName, email, status, providerRoles,
    type) => {
    try {
      return await http.put(`/api/admin/accounts/users/${id}`, {
        firstName,
        lastName,
        email,
        status,
        providerRoles,
        type
      }, {
        headers: {
          'Content-Type': 'application/vnd.carry1st.accounts.adminuser+json'
        }
      });
    } catch (error) {
      return error.response;
    }
  };

  resendInvitation = async (id) => {
    try {
      const response = await http.get(`/api/admin/accounts/users/invite/${id}`, {
        headers: {
          'Content-Type': 'application/vnd.carry1st.accounts.adminuser+json'
        },
        data: {},
        params: {}
      });

      return response.data;
    } catch (error) {
      return error.response;
    }
  };

  deleteAdminUser = async (id) => {
    try {
      return await http.delete(`/api/admin/accounts/users/${id}`, {
        headers: {
          'Content-Type': 'application/vnd.carry1st.accounts.adminuser+json'
        },
        data: {}
      });
    } catch (error) {
      return error.response;
    }
  };

  invite = async (firstName, lastName, email, providerRoles, type) => {
    try {
      return await http.post('/api/admin/accounts/users/invite', {
        firstName,
        lastName,
        email,
        providerRoles,
        type
      }, {
        headers: {
          'Content-Type': 'application/vnd.carry1st.accounts.adminuser+json'
        }
      });
    } catch (error) {
      return error.response;
    }
  };

  providerInvite = async (providerId, firstName, lastName, email, roleId, type) => {
    const providerRoles = [{ providerId, roleId }];
    return this.invite(firstName, lastName, email, providerRoles, type);
  }

  getPermissions = async (page, order, orderBy, searchTerm, size, status, internal, external) => {
    try {
      const response = await http.get('/api/admin/accounts/permissions', {
        headers: {
          'Content-Type': 'application/vnd.carry1st.accounts.permission+json'
        },
        data: {},
        params: {
          page,
          size,
          order,
          orderBy,
          searchTerm,
          status,
          internal,
          external
        }
      });

      return response.data;
    } catch (error) {
      return error.response;
    }
  }

  getPermission = async (id) => {
    try {
      const response = await http.get(`/api/admin/accounts/permissions/${id}`, {
        headers: {
          'Content-Type': 'application/vnd.carry1st.accounts.permission+json'
        },
        data: {}
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }

  updatePermission = async (id, name, status, internal, external) => {
    try {
      return await http.put(`/api/admin/accounts/permissions/${id}`, {
        name,
        status,
        internal,
        external
      },
      {
        headers: {
          'Content-Type': 'application/vnd.carry1st.accounts.permission+json'
        }
      });
    } catch (error) {
      return error.response;
    }
  }

  updateAccount = async (data) => http.put(
    '/api/admin/accounts/profile',
    data,
    {
      headers: {
        'Content-Type': 'application/vnd.carry1st.accounts.profile+json'
      }
    }
  );
}

const accountService = new AccountService();

export default accountService;

import http from 'src/http/HttpCommon';

class UserService {
  getUserById = (id) => http.get(`/api/admin/accounts/users/${id}`, {
    headers: {
      'Content-Type': 'application/vnd.carry1st.accounts.adminuser+json'
    },
    data: {}
  });
}

const userService = new UserService();

export default userService;

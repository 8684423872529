import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, colors, Container, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 128,
    color: colors.common.white
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

function Features({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          align="center"
        >
          Africa is home to the next generation of mobile gamers
        </Typography>
        <Box mt={3}>
          <Typography
            variant="body1"
            align="center"
          >
            Sub-Saharan Africa will add 400+ million smartphones in the next five years, doubling the number in North
            America today.
          </Typography>

        </Box>
      </Container>
    </div>
  );
}

Features.propTypes = {
  className: PropTypes.string
};

export default Features;

import {
  all, call, put, takeLatest, select
} from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import {
  FETCH_ROLES, RECEIVE_ROLES, FETCH_ROLES_FAILURE, FETCH_PERMISSIONS, RECEIVE_PERMISSIONS
} from 'src/store/actions/accountActions';
import { PROVIDERS_SUCCESS } from 'src/store/actions/providerActions';
import authService from 'src/services/authService';
import userService from 'src/services/userService';
import { selectAccount, selectActiveProvider, selectRolesByProviderId } from 'src/store/selectors/accountSelectors';

function* fetchRoles() {
  try {
    const isAuthenticated = yield call(authService.isAuthenticated);
    if (isAuthenticated) {
      const accessToken = yield call(authService.getAccessToken);
      const { userId } = jwtDecode(accessToken);
      const { data } = yield call(userService.getUserById, userId);
      const { roles } = data;
      yield put({ type: RECEIVE_ROLES, payload: { roles } });
    } else {
      yield put({ type: FETCH_ROLES_FAILURE, payload: { error: new Error('User not authenticated.') } });
    }
  } catch (error) {
    yield put({ type: FETCH_ROLES_FAILURE, payload: { error } });
  }
}

function* fetchPermissions() {
  const { roles, user } = yield select(selectAccount);
  const provider = yield select(selectActiveProvider);

  if (!user || !roles || !provider) {
    return;
  }

  const { role } = yield select(selectRolesByProviderId);

  if (!role) {
    return;
  }

  const { id } = role;
  const { permissions } = yield call(authService.getRolePermissions, id);
  yield put({
    type: RECEIVE_PERMISSIONS,
    payload: { permissions: permissions.map((item) => item.code) }
  });
}

export default function* root() {
  yield all([
    takeLatest(FETCH_ROLES, fetchRoles),
    takeLatest([RECEIVE_ROLES, PROVIDERS_SUCCESS, FETCH_PERMISSIONS], fetchPermissions),
  ]);
}

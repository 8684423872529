import axios from 'axios';
import deviceCommon from 'src/http/DeviceCommon';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: process.env.REACT_APP_BASIC_AUTH_STRING
  }
});

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    config.headers['X-DEVICE-ID'] = deviceCommon.getDeviceId();

    if (accessToken) {
      config.headers.AccessToken = `Bearer ${accessToken}`;
    }

    const activeProviderId = localStorage.getItem('activeProviderId');
    if (activeProviderId) {
      config.headers['X-PROVIDER-ID'] = activeProviderId;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;

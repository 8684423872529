import uuid from 'react-uuid';

class DeviceCommon {
  getDeviceId = () => {
    if (!localStorage.getItem('deviceId')) {
      localStorage.setItem('deviceId', uuid());
    }

    return localStorage.getItem('deviceId');
  };
}

const deviceCommon = new DeviceCommon();

export default deviceCommon;

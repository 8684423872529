import React from 'react';

function TopBarLogo() {
  return (
    <img
      alt="Logo"
      src="/static/carry1stlogoWhite.png"
      width="128"
      height="32"
    />
  );
}

export default TopBarLogo;
